import React, { useState } from 'react'
import {
    Box,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Link, IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Banner from '../../Assets/Image/Banner/banner.jpg'
import MobileBanner from '../../Assets/Image/Banner/mobileBanner.jpg'
import Kitchen from '../../Assets/Image/PageImage/kitchen.jpg'
import Footer from '../../Component/Footer'
import YoutubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { keyframes } from '@mui/system';


function Index() {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [nameError, setNameError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const pulse = keyframes({
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.1)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    });


    const handleSubmit = (event: any) => {
        event.preventDefault();

        // Reset errors
        setNameError(false);
        setMobileError(false);

        // Validate inputs
        if (!name) {
            setNameError(true);
            return;
        }

        if (!/^[0-9]{10}$/.test(mobile)) {
            setMobileError(true);
            return;
        }

        // Submit form
        console.log(`Name: ${name}, Mobile: ${mobile}`);

        // Close modal
        handleClose();
    };

    function getWhatsAppLink() {
        const phoneNumber = '916385861829'; // Replace with your own phone number
        const url = `https://wa.me/${phoneNumber}`;
        return url;
    }


    const BannerImg = useMediaQuery('(min-width:600px)')
        ? Banner
        : MobileBanner;
    return (

        <div>
            <Box sx={{ position: 'relative' }}>
                <img
                    className="object-cover"
                    src={BannerImg}
                    alt="Banner"
                    style={{ maxWidth: '100%', height: 'auto' }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '30%',
                        left: '80%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        width: '100%',
                        maxWidth: '500px',
                        margin: '0 auto',
                        '@media (max-width:600px)': {
                            top: '59.5%',
                            left: '50%',
                            width: '70%',
                            maxWidth: '300px'
                        },
                    }}
                >
                    <Button
                        variant="contained"
                        size="small"

                        sx={{
                            borderRadius: '50px',
                            bgcolor: '#fb9a24',
                            color: 'white',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundImage: 'linear-gradient(to right, #FCE38A, #FBBF24)',
                                boxShadow: '0px 0px 10px 0px rgba(255,255,255,0.75)',
                                transition: 'box-shadow 0.3s ease-out',
                            },
                            width: { xs: '50%', sm: 'auto' },
                            fontSize: { xs: '0.8rem', sm: '1rem' }, // Add this line to reduce font size
                            p: isSmallScreen ? 1 : 1, // Add this line to reduce padding
                            '@media (max-width:600px)': {

                                width: '80%',
                                maxWidth: '600px'
                            },
                            '@media (min-width: 601px) and (max-width: 960px)': {
                                top: '15%',
                                right: '1%',
                                width: '50%',
                                maxWidth: '150px',
                                fontSize: '0.6rem'
                            },
                            '@media (min-width: 961px) and (max-width: 1280px)': {

                                fontSize: '0.8rem',
                                width: '40%',
                                maxWidth: '180px'
                            },
                            '@media (min-width: 1281px)': {

                                width: '50%',
                                maxWidth: '250px',
                                fontSize: '0.9rem',
                            }
                        }}
                    >
                        <Link
                            href={getWhatsAppLink()}
                            target="_blank"
                            underline="none"
                            color="inherit"
                        >
                            Click here to order
                        </Link>
                    </Button>
                </Box>
                <Box sx={{
                    display: 'flex',
                    position: 'absolute',
                    bottom: '20%',
                    left: '80%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: '500px',
                    alignItems: 'center',
                    margin: '0 auto',
                    '@media (max-width:600px)': {
                        top: '80.5%',
                        left: '40%',
                        width: '70%',
                        maxWidth: '300px'
                    },
                    color: 'white',
                    fontWeight: 'bold'
                }}>
                    Connect with us on:
                    <Box sx={{ display: 'flex', gap: 2, left: '10%', position: 'relative' }}>
                        <IconButton
                            href="http://www.youtube.com/@ThumiKitchen"
                            target="_blank"
                            aria-label="Youtube"
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                padding: 1,
                                '&:hover': {
                                    animation: `${pulse} 0.5s linear`,
                                    backgroundColor: 'red',
                                    '& svg': {
                                        color: 'white'
                                    }
                                },
                            }}
                        >
                            <YoutubeIcon sx={{ fontSize: 28, color: '#f21818', }} />
                        </IconButton>
                        <IconButton
                            href="http://www.instagram.com/thumi.kitchen"
                            target="_blank"
                            aria-label="Instagram"
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                padding: 1,
                                '&:hover': {
                                    animation: `${pulse} 0.5s linear`,
                                    backgroundColor: 'red',
                                    '& svg': {
                                        color: 'white'
                                    }
                                },
                            }}
                        >
                            <InstagramIcon sx={{
                                fontSize: 28, color: '#e4405f',
                            }} />
                        </IconButton>
                    </Box>
                </Box>

            </Box>

            {/* <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enter your details</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your name and mobile number to place your order.
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            error={nameError}
                            helperText={nameError && 'Please enter your name.'}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Mobile Number"
                            type="tel"
                            value={mobile}
                            onChange={(event) => setMobile(event.target.value)}
                            error={mobileError}
                            helperText={mobileError && 'Please enter a valid 10-digit mobile number.'}
                            fullWidth
                            margin="normal"
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog> */}
            {/* <Footer /> */}
        </div >
    )
}

export default Index