import React from 'react';
import logo from './logo.svg';
import './App.css';
import Router from '../src/Route'
function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
